import Typography from "typography"

//https://fonts.google.com/specimen/IM+Fell+English+SC?preview.text_type=custom#standard-styles
const theme = {
  title: "IM Fell English",
  baseFontSize: "21px",
  baseLineHeight: 1.5,
  googleFonts: [
    {
      name: "IM Fell English",
      styles: ["400", "400i", "600"],
    },
    {
      name: "IM Fell English SC",
      styles: ["400", "400i", "600"],
    },
  ],
  headerFontFamily: ["IM Fell English SC", "serif"],
  bodyFontFamily: ["IM Fell English", "serif"],
  headerColor: "hsla(0,0%,0%,0.9)",
  bodyColor: "hsla(0,0%,0%,0.73)",
  headerWeight: "700",
  bodyWeight: 400,
  boldWeight: 600,
};

const typography = new Typography(theme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
